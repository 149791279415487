li {
  margin-left: -10px;
}
img.feedback {
  max-height: 200px;
  max-width: 50%;
}
img.upload {
  max-height: 400px;
  max-width: 100%;
}
.text-justify {
  text-align: justify;
}

/* For Material Symbols: */
.unfilled {
  font-variation-settings: 'FILL' 0, 'wght' 700, 'GRAD' 200, 'opsz' 48;
}
.filled {
  font-variation-settings: 'FILL' 1, 'wght' 500, 'GRAD' 200, 'opsz' 20;
}
.app {
  text-align: center;
}
.recaptcha {
  height: 78px;
  width: 304px;
}
.toast {
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
}
.toast > div > .icon {
  margin-left: -5px;
  margin-right: 5px;
}

.footer-fade {
  background:linear-gradient(rgba(var(--bs-tertiary-bg-rgb), 0), rgba(var(--bs-tertiary-bg-rgb), 1));
  height: 6px;
}
.header-fade {
  background:linear-gradient(rgba(var(--bs-tertiary-bg-rgb), 1), rgba(var(--bs-tertiary-bg-rgb), 0));
  height: 6px;
  margin-top: 70px;
}

.controls {
  margin-top: -10px;
}
.control {
  max-height: 82px;
  min-height: 82px;
  max-width: 82px;
  min-width: 82px;
}
.control > .name {
  margin-top: -8px;
}
.tools {
  margin-bottom: 16px;
}
.tools > .title {
  margin-bottom: 5px;
  margin-left: 22px;
}
.tools > .title:after {
  content: "";
  display: inline-block;
  height: 1px;
  position: absolute;
  margin-top: 11.5px;
  margin-left: 7px;
}
.tools > .title:before {
  content: "";
  display: inline-block;
  height: 1px;
  position: absolute;
  margin-top: 11.5px;
  left: 15px;
  width: 15px;
}
.tools.dev > .title:after {
  background: rgb(var(--bs-info-rgb)) !important;
  width: 195px;
}
.tools.prompt > .title:after {
  background: rgb(var(--bs-warning-rgb)) !important;
  width: 224px;
}
.tools.dev > .title:before {
  background: rgb(var(--bs-info-rgb)) !important;
}
.tools.prompt > .title:before {
  background: rgb(var(--bs-warning-rgb)) !important;
}
.font-icon {
  font-size: 66px;
  font-variation-settings: 'FILL' 0, 'wght' 210, 'GRAD' 200, 'opsz' 48;
  margin-top: -8px;
}
.font-name {
  font-size: calc(var(--control-name-font) * var(--font-multiply) * var(--font-multiply));
  margin-top: -13px;
}
.mode-auto {
  font-size: 46px;
  margin-bottom: 10px;
  margin-top: 4px;
}
.mode-dark {
  font-size: 54px;
  margin-bottom: 9px;
  margin-top: -3px;
}

.scroll-btn {
  --bs-bg-opacity: 0.50;
  background: no-repeat radial-gradient(circle closest-side, rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) 100%,#0000);
  height: 64px;
  margin-bottom: 6px;
  width: 80px;
}
.scroll-btn.reverse {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}
.scroll-icon {
  font-size: 80px;
  margin-top: -8px;
}

.avatar {
  margin-bottom: -10px;
}
.avatar > .icon {
  margin-right: 10px;
  margin-top: -8px;
}
.avatar > .name {
  margin-right: 9px;
}
.bubble {
  position: relative;
}
.chat {
  margin-left: auto;
  margin-right: auto;
  min-width: 340px;
  max-width: 820px;
  padding-top: 74px;
}
.content {
  overflow-y: hidden;
  resize: none;
}
.counter {
  margin-bottom: -8px;
  margin-top: -2px;
}
.counter.message {
  padding-right: 56px;
}
.loader-bubble {
  width: 100px;
}
.loader {
  animation: l 1s infinite linear;
  aspect-ratio: 2;
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  margin: 0 auto 0 auto; /* top | right | bottom | left */
  width: 48px;
  --_g: no-repeat radial-gradient(circle closest-side,#fff 90%,#0000);
}
@keyframes l {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
.chat-loader {
  --chat-loader-color: var(--bs-emphasis-color);
  --chat-loader-size: 20px;
  --chat-loader-height: calc(var(--chat-loader-size) * (2 + 1 / 3));
  --chat-loader-width: calc(var(--chat-loader-size) * (4 + 1 / 6));
  --_g: no-repeat radial-gradient(farthest-side, var(--chat-loader-color) 94%, #0000);
  animation: cl-0 1.5s linear infinite;
  background:
    var(--_g) 50%  0,
    var(--_g) 100% 0;
  background-size: var(--chat-loader-size) var(--chat-loader-size);
  height: var(--chat-loader-height);
  margin-top: 280px;
  position: relative;
  width: var(--chat-loader-width);
}
.chat-loader:before {
  animation:
    cl-1 1.5s linear infinite,
    cl-2 0.5s cubic-bezier(0, 1000, 0.8, 400) infinite;
  aspect-ratio: 1;
  background: var(--chat-loader-color);
  border-radius: 50%;
  content: "";
  height: var(--chat-loader-size);
  left:0;
  position: absolute;
  top:0;
}
@keyframes cl-0 {
  0%,31%  {background-position: 50% 0   ,100% 0}
  33%     {background-position: 50% 100%,100% 0}
  43%,64% {background-position: 50% 0   ,100% 0}
  66%     {background-position: 50% 0   ,100% 100%}
  79%     {background-position: 50% 0   ,100% 0}
  100%    {transform:translateX(calc(var(--chat-loader-width) / -2.67))}
}
@keyframes cl-1 {
  100% {left:calc(100% + 11px)}
}
@keyframes cl-2 {
  100% {top:-0.1px}
}
.img-loader {
  animation: il 1.2s infinite linear;
  background: radial-gradient(closest-side at 6px 50%, #fff 94%, #0000) 0/calc(92%/6) 100%;
  height: 12px;
  margin-bottom: 6px;
  margin-top: 6px;
  width: 100%;
  -webkit-mask: linear-gradient(90deg, #0000, #fff 10% 90%, #0000);
          mask: linear-gradient(90deg, #0000, #fff 10% 90%, #0000);
}
@keyframes il {
  100% {background-position: 36.36%}
}
.img-loader.content {
  margin-bottom: 6px !important;
  margin-top: 15px !important;
}
.translate-loader {
  animation: l1 1s infinite;
  aspect-ratio: 1;
  border: 8px solid;
  border-color: var(--bs-emphasis-color) #0000;
  border-radius: 50%;
  margin-bottom: 10px;
  margin-top: 4px;
  width: 45px;
}
@keyframes l1 {to{transform: rotate(.5turn)}}

.dropdown-menu {
  min-width: auto !important;
}
/* Hide arrow on language dropdown: */
.dropdown-toggle::after {
  display: none !important; 
}

.sentiment {
  font-size: 82px;
}

.terms {
  padding-bottom: 74px;
  padding-top: 74px;
}

/* Font sizes: */
/* Update oneLineHeight values in TextArea.js if you change --content-font */
:root {
  --font-multiply: 1.25;
  --avatar-icon-font: 40px;
  --avatar-name-font: 11px;
  --content-font: 16px;
  --control-icon-font: 42px;
  --control-name-font: 8px;
  --copyright-font: 14px;
  --counter-font: 10px;
  --default-icon-font: 24px;
  --h2-multiply: 1.25 * 1.25;
  --h4-multiply: 1.25 * 1.1;
  --h5-multiply: 1.25;
}
.avatar > .icon.large {
  font-size: calc(var(--avatar-icon-font) * var(--font-multiply) * var(--font-multiply));
}
.avatar > .icon.medium {
  font-size: calc(var(--avatar-icon-font) * var(--font-multiply));
}
.avatar > .icon.small {
  font-size: var(--avatar-icon-font);
}
.avatar > .name.large {
  font-size: calc(var(--avatar-name-font) * var(--font-multiply) * var(--font-multiply));
  margin-top: calc(-1 * var(--avatar-name-font) * var(--font-multiply) * var(--font-multiply));
}
.avatar > .name.medium {
  font-size: calc(var(--avatar-name-font) * var(--font-multiply));
  margin-top: calc(-1 * var(--avatar-name-font) * var(--font-multiply));
}
.avatar > .name.small {
  font-size: var(--avatar-name-font);
  margin-top: calc(-1 * calc(var(--avatar-name-font)));
}
.content.large {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--font-multiply));
}
.content.large > h2 {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--font-multiply) * var(--h2-multiply));
}
.content.large > h4 {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--font-multiply) * var(--h4-multiply));
}
.content.large > h5 {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--font-multiply) * var(--h5-multiply));
}
.content.medium {
  font-size: calc(var(--content-font) * var(--font-multiply));
}
.content.medium > h2 {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--h2-multiply));
}
.content.medium > h4 {
  font-size: calc(var(--content-font) * var(--font-multiply) * var(--h4-multiply));
}
.content.medium > h5 {
  font-size: calc(var(--content-font) * var(--h5-multiply));
}
.content.small {
  font-size: var(--content-font);
}
.content.small > h2 {
  font-size: calc(var(--content-font) * var(--h2-multiply));
}
.content.small > h4 {
  font-size: calc(var(--content-font) * var(--h4-multiply));
}
.content.small > h5 {
  font-size: calc(var(--content-font) * var(--h5-multiply));
}
.control > .icon.large {
  font-size: calc(var(--control-icon-font) * var(--font-multiply) * var(--font-multiply));
}
.control > .icon.medium {
  font-size: calc(var(--control-icon-font) * var(--font-multiply));
}
.control > .icon.small {
  font-size: var(--control-icon-font);
}
.control > .name.large {
  font-size: calc(var(--control-name-font) * var(--font-multiply) * var(--font-multiply));
}
.control > .name.medium {
  font-size: calc(var(--control-name-font) * var(--font-multiply));
}
.control > .name.small {
  font-size: var(--control-name-font);
}
.copyright.large {
  font-size: calc(var(--copyright-font) * var(--font-multiply));
}
.copyright.medium {
  font-size: calc(var(--copyright-font) * calc(var(--font-multiply) / 2 + 0.5));
}
.copyright.small {
  font-size: var(--copyright-font);
}
.counter.large {
  font-size: calc(var(--counter-font) * var(--font-multiply) * var(--font-multiply));
}
.counter.medium {
  font-size: calc(var(--counter-font) * var(--font-multiply));
}
.counter.small {
  font-size: var(--counter-font);
}
.default-icon.large {
  font-size: calc(var(--default-icon-font) * var(--font-multiply) * var(--font-multiply));
}
.default-icon.medium {
  font-size: calc(var(--default-icon-font) * var(--font-multiply));
}
.default-icon.small {
  font-size: var(--default-icon-font);
}
